import React from 'react';
import Layout from '../components/layout';

const headerText = 'LAND ACKNOWLEDGEMENT';

const Accessibility = () => {
  return (
    <Layout headerText={headerText} heroFont="has-text-white" heroBg="fields">
      <section className="section">
        <div className="content">
          <div className="container">
            <div>
              <p>
                The iDECIDE Development and Evaluation Team would like to
                acknowledge, with gratitude and humility, that the entirety of
                this program takes place on Indigenous land. We acknowledge that
                the systems in which we work were founded upon the exclusion and
                erasure of many Indigenous communities. We make the commitment
                to continue to work to understand the historical and current
                experiences of Native peoples—through collaborative
                partnerships, enrollment and retention efforts, community
                service, and more—to help inform the work we do and advance the
                creation of more inclusive spaces for all.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Accessibility;
